.documents {
  padding: 20px;

  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  align-items: center;
  justify-content: center;
}

.document {
  a {
    font-style: italic;
    text-decoration: underline;
    text-decoration-color: #BF1932;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    display: block;
    width: 32px;
    height: 32px;
    fill: #BF1932;
    margin: 5px;
  }
}

@media (max-width: 768px) {
  .documents {
    flex-direction: column;
    gap: 10px;
    justify-content: start;
  }
}