* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    height: 100%;
    font-size: 20px;
    font-family: 'Lora', serif;
}

code {
    font-family: 'Open Sans', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 10px;
}

.item_font-red {
    color: #BF1932;
}

.charityApp-wrapper {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5em;
    justify-content: center;
}

a {
    display: block;
    text-decoration: none;
    cursor: pointer;
    color: black;
}

h2 {
    margin: 20px;
    font-size: 36px;
    color: #BF1932;
}

p {
    font-size: 20px;
}

ul {
    list-style-type: none;
}



// .banner {
//     position: relative;
//     width: 100%;
//     height: 100vh;
//     background-size: cover;
//     background-image: url(../assets/background.jpg);
// }