.requisites-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

  & >* {
    width: 100%;
  }
  .requisites__item {
    margin: 20px 0;
    font-size: 20px;
    font-weight: bolder;
    text-align: center;
    vertical-align: middle;
  }
}