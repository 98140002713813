.footer-wrapper {
  width: 100%;
  margin-bottom: 100px;
}

.footer {
  width: 100%;
  padding: 0.5rem;
  text-align: center;

  font-style: normal;
  font-weight: bold;

  .footer__contacts {
    max-width: 1400px;
    margin: 20px auto;
    padding: 0 20px;
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}

.contacts__item {
  display: flex;
  margin: 5px 0;
  font-weight: bolder;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;

  svg {
      margin-right: 10px;
      fill: #BF1932;
      width: 24px;
      height: 24px;
  }
}

@media (max-width: 768.98px) {
  .footer__contacts {
    flex-direction: column;
    align-items: center;
  }
}