.fullscreen {
    min-height: 90vh;
    display: flex;

    &__body {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
    }
}

.homepage {
    min-width: 320px;
    position: relative;

    &__logo {
        width: 50%;
        height: 50%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: auto 100%;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    &__title {
        margin: 10px 0;
        text-align: center;
        font-size: 4em;
        color: #BF1932;
    }

    &__description {
        text-align: center;
        
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__social {
        margin: 20px 0;
        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    &__slogan {
        text-align: center;
    }
}

.social__item {
    margin: 10px 0;
    font-weight: bolder;
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;

    svg {
        margin-right: 20px;
        fill: #BF1932;
    }
}

@media (max-width: 767.98px) {
    .fullscreen__body {
        align-items: center;
        position: relative;
        gap: 10px;
        margin-bottom: 100px;
    }

    .homepage {
        &__description {
            font-size: 18px;
        }

        &__title {
            font-size: 34px;
            margin: 30px 0 20px 0;
        }

        &__social {
            justify-content: end;
        }

        &__logo {
            display: none;
        }
    }
}

@media (min-width: 768px) and (max-width: 900px) {
    .homepage {
        &__logo {
            background-size: 100% auto;
        }
    }
}