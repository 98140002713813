.text__center {
  text-align: center;
}

.events {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  justify-content: space-around;
}

.event {
  width: 750px;
  height: 400px;
  border-radius: 10px;

  position: relative;
  margin: 30px 10px;
  display: flex;
  box-shadow: 0px 10px 15px rgba(191, 25, 50, .7);
  transition: 0.4s ease-in-out;

  &__img {
    height: 100%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    img {
      max-height: 100%;
      border-radius: 10px 0 0 10px;
    }
  }

  &__description {
    flex: 1 0;

    margin: 0px 10px;
    padding: 0px 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 24px;
    font-weight: 600;
    color: #BF1932;
    text-align: center;
  }
  
  &__show-more {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 10px;
    padding: 10px 20px;
    text-transform: uppercase;
    background-color: #BF1932;
    color: #fff;
    border-radius: 5px;
    border: none;
    transition: all 0.4s;
    border: 1px solid transparent;

    span {
      position: relative;
      transition: all 0.4s;
      letter-spacing: 2px;

      &::after {
        content: "\00bb";
        position: absolute;
        top: 0;
        right: -15px;
        opacity: 0;
        transition: all 0.4s;
      }
    }

    &:hover {
      background-color: #fff;
      color: #BF1932;
      border: 1px solid #BF1932;

      span {
        padding-right: 30px;

        &::after {
          top: 0;
          right: 0px;
          opacity: 1;
        }
      }
    }
  }
}

.album {
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;

  &__back {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      fill: #BF1932;
      width: 24px;
      height: 24px;
    }
  }

  &__description {
    margin: 20px 30px;
    padding: 0px 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5em;
  }

  &__photos {
    margin: 20px 0px;
    display: flex;
    gap: 1.5em;
    flex-wrap: wrap;
  
    align-items: center;
    justify-content: center;

    img {
      width: 200px;
      height: 200px;
      cursor: pointer;
      display: block;
      object-fit: cover;
    }
  }

  &__video {
    height: 400px;
    width: 300px;
  }
}

@media (max-width: 425.98px) {
  .events {
    flex-direction: column;
    gap: 1em;
    align-items: center;
    justify-content: center;
  }

  .album__video {
    height: 300px;
    width: 250px;
  }
}

@media (max-width: 768px) {
  .event {
    width: 320px;
    height: 420px;
    
    &__description {
      h3 {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .events {
    flex-direction: row;
    gap: 1em;
    align-items: center;
    justify-content: center;
  }

  .event {
    margin: 0 auto;
    padding: 5px;
    display: flex;
    flex-direction: column;

    &__description {
      margin: 0;
      padding: 0;
    }

    &__show-more {
      position: relative;
      margin: 5px;
      padding: 5px;
      background-color: #BF1932;
      color: #fff;
      border-radius: 5px;
      border: none;
      font-size: 18px;
      text-align: center;
    }
  }

  .album {
    padding: 0px;

    &__back {
      margin: 10px;
      justify-content: flex-start;
      gap: 10px;
    }
  
    &__description {
      margin: 0px;
      padding: 0px;
    }
  }
}