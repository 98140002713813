.content-wrapper {
  padding: 100px 0 0 0;
  min-height: 100vh;
  
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  padding: 0 40px;
}
