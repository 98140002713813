.gratitudes {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  justify-content: center;
}

.gratitude {
  &__img{
    width: 320px;
    height: 480px;
    border-radius: 10px;
    position: relative;
  }
  &__name {
    display: flex;
    max-width: 320px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  &__img-full {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(191, 25, 50, .7);
    visibility: hidden;

    svg {
      width: 48px;
      height: 48px;
      fill: #fff;
    }
  }
}

.gratitude:hover {
  .gratitude__img-full {
    visibility: visible;
  }
}