body._lock {
  overflow: hidden;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #BF1932;

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    margin: 0 15px;
    flex: 0 0 75px;
    z-index: 10;
    svg {
      fill: #fff;
    }
  }

  &__menu {
    flex: 1 0 auto;
  }
}

.menu {
  &__icon {
    display: none;
  }

  &__list > li {
    position: relative;
    margin: 0 0 0 20px;
  }

  &__link {
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }

  &__arrow {
    display: none;
  }
}

.menu__sub {
  &-list {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #BF1932;
    padding: 20px;
    min-width: 200px;

    > li {
      margin: 0 0 10px 0;
      &:last-child {
        margin: 0;
      }
    }
  }

  &-link {
    color: #fff;
    text-align: center;
    &:hover {
      text-decoration: underline;
    }
  }
}

.menu__desktop .menu__list > li:hover .menu__sub-list {
  opacity: 1;
  visibility: visible;
  transform: translate(0px, 0px);
  pointer-events: all;
}

.menu__mobile {
  .menu__arrow {
    display: block;
    width: 0;
    height: 0;
    margin: 0 0 0 10px;
    transition: transform 0.3s ease 0s;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #fff;
  }

  .menu__list > li {
    display: flex;
    align-items: center;
  }

  .menu__list > li._active {
    .menu__sub-list {
      opacity: 1;
      visibility: visible;
      transform: translate(0px, 0px);
      pointer-events: all;
    }

    .menu__arrow {
      transform: rotate(-180deg);
    }

  }

  .menu__link {
    flex: 1 1 auto;
  }
}

@media (min-width: 767px) {
  .menu {
    &__list {
      display: flex;
      align-items: center;
      justify-content: space-around;
  
      > li {
        list-style: none;
        margin: 0 0 0 10px;
      }
    }
  }

  .menu__sub {
    &-list {
      transform: translate(0, 10%);
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: all 0.3s ease 0s;
    }
  }
}

@media (max-width: 767px) {
  .header__menu {
    flex: 0 1 auto;
  }

  .menu {
    &__icon {
      z-index: 5;
      display: block;
      position: relative;
      width: 30px;
      height: 18px;
      cursor: pointer;

      & span,
      &::before,
      &::after {
        left: 0;
        position: absolute;
        height: 10%;
        width: 100%;
        transition: all 0.3s ease 0s;
        background-color: #fff;
      }
      &::before,
      &::after {
        content: "";
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }

      span {
        top: 50%;
        transform: scale(1);
      }

      &._active {
        span {
          transform: scale(0);
        }

        &::before {
          top: 50%;
          transform: rotate(-45deg) translate(0, -50%);
        }

        &::after {
          bottom: 50%;
          transform: rotate(45deg) translate(0, 50%);
        }
      }
    }

    &__body {
      position: fixed;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.9);
      padding: 100px 30px 30px 30px;
      transition: left 0.3s ease 0s;
      overflow: auto;

      &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background-color: #BF1932;
        z-index: 2;
      }

      &._active {
        left: 0;
      }
    }

    &__list > li {
      flex-wrap: wrap;
      margin: 0px 0px 30px 0px;

      &:last-child {
        margin-bottom: 0px;
      }

      &._active {
        .menu__sub-list {
            display: block;
          }
      }
    }

    &__link {
      font-size: 24px;
    }
  }

  .menu__sub {
    &-list {
      position: relative;
      background-color: #fff;
      flex: 1 1 100%;
      margin: 20px 0px 0px 0px;
      display: none;
    }

    &-link {
      font-size: 20px;
      color: #BF1932;
      padding: 10px 0px 0px 0px;
    }
  }

}
