.partners {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5em;
  justify-content: center;
}

.partner {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5em;
  justify-content: center;
  position: relative;

  width: 240px;
  height: 240px;
    
  &__logo {
    display: block;
    max-width:100%;
    max-height:100%;
  }

  &__info {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: rgba(191, 25, 50, .7);
    visibility: hidden;
  }

  &__name {
    color: #fff;
    font-size: 2em;
    margin: 1em auto;
    text-align: center;
  }

  &__links {
    display: flex;
    gap: 1.5em;
  }

  &__link svg {
    fill: #fff;
    width: 2em;
    height: 2em;
  }
}

.partner:hover {
  .partner__info {
    visibility: visible;
  }
  
  .partner__logo {
    filter: blur(7px);
  }
}

@media (min-width: 481px) {
  .partners {
    justify-content: space-around;
  }

  .partner {  
    width: 320px;
    height: 320px;
  }
}

@media (min-width: 769px) {
  .partner {  
    width: 400px;
    height: 400px;
  }
}