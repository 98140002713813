.team {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  justify-content: center;
}

.teammate {
  width: 320px;
  height: 480px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: flex-end;
  box-shadow: 0px 7px 10px rgba(191, 25, 50, .7);
  transition: 0.5s ease-in-out;

  &__info {
    padding: 2em 1em 2em 1em;
    display: flex;
    flex-direction: column;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(191, 25, 50, .0), rgba(191, 25, 50, 1));
        opacity: 1;
        border-radius: 10px;
      }
  }

  &__name {
    color: #fff;
    font-size: 24px;
    margin: 0 0 10px;
    z-index: 1;
  }

  &__role {
    color: #fff;
    font-size: 18px;
    z-index: 1;
  }
}

// .teammate:before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   display: block;
//   width: 100%;
//   height: 100%;
//   background: linear-gradient(to bottom, rgba(191, 25, 50, .1), rgba(191, 25, 50, 1));
//   opacity: 1;
// }