@import "_Orhpanage.scss";

.project {
    &__title {
        margin: 0 auto;
        text-transform: uppercase;
        font-size: 24px;
        color: #BF1932;
        text-align: center; 
        vertical-align: middle;
    }

    &__description {
        line-height: 1.5;
    }

    &__slogan {
        margin: 0 auto;
        font-size: 24px;
        font-weight: bold;
        color: #BF1932;
        text-align: center; 
        vertical-align: middle;
        margin: 10px 0;
    }
}
