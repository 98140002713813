.orhpanage {
    display: flex;
    flex-direction: column;

    img {
        max-width:100%;
        max-height:100%;
    }
}

@media (min-width: 769px) {
    .orhpanage__imgs {
        display: flex;
        gap: 0.5em;
    }

    .orhpanage__img {
        flex: 1;
    }
}


@media (min-width: 1201px) {
    .orhpanage {
        flex-direction: row;

        .project__content,
        .orhpanage__imgs {
            flex: 1;
        }

        .orhpanage__imgs {
            max-height: 600px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 0.5em;
        }

        .orhpanage__img_primary {
            grid-column: 1 / 3;
            grid-row: 1 / 3;
            min-height: 400px;
            justify-self: center;
        }

        .orhpanage__img_secondary {
            min-height: 200px;
            &:nth-child(2n) {
                justify-self: right;
            }
        }
    }
}